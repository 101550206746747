import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';
import { swiperObserver } from '../utils/swiper.utils.js';

let teamTsSwiperWr = document.querySelectorAll('.team-ts-swiper-wr');
teamTsSwiperWr.forEach((el) => {
    if (el) {
        let swiperEl = el.querySelector('.swiper');
        let nextEl = el.querySelector('.swiper-button-next');
        let prevEl = el.querySelector('.swiper-button-prev');
        let slidesCount = el.querySelectorAll('.swiper-slide').length;
        let wrapper = swiperEl.querySelector('.swiper-wrapper');
        let swiper = new Swiper(swiperEl, {
            modules: [Autoplay, Navigation],
            spaceBetween: 20,
            slidesPerView: 1,
            navigation: {
                nextEl: nextEl,
                prevEl: prevEl,
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
            },
            speed: 800,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
        });
    }
});

