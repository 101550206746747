import './components/global.component.js';
import './utils/vh.utils.js';
import './utils/simplebar.utils.js';

import './components/header.component.js';
import './components/headerDropdown.component.js';
import './components/sliders.component.js';
import './components/nameCut.component.js';
import './components/heroParallax.component.js';
import './components/customAccordion.component.js';
import './components/doubleRangeSlider.component.js';
import './components/culture-gallery.component.js';

import './components/industries.component.js';
/*swiper components*/
import './components/values-swiper.component.js';
import './components/dev-swiper.component.js';
import './components/story-swiper.component.js';
import './components/talent-swiper.component.js';
import './components/team-ts-swiper.component.js';
import './components/studies-swiper.component.js';
import './components/count-swiper.component.js';
import './components/hero-carousel-swiper.component.js';
/*swiper*/

import './libs/simplebar.lib.js'
import './libs/countUp.lib.js';
import './libs/aos.lib.js'
import './libs/gsap.lib.js'
import './libs/choices.lib.js'

console.log('11')
console.log('11')

window.jsScrollToEl = function jsScrollToEl(targetID) {
    let config = targetID.split('|');
    let target = config[0];
    let duration = config[1] && !isNaN(config[1]) ? parseInt(config[1]) : 800;
    let paddingTop = config[2] && !isNaN(config[2]) ? parseInt(config[2]) : 0;

    let targetElement = document.getElementById(target) || document.querySelector(target);

    if (targetElement) {
        let targetOffsetTop = targetElement.getBoundingClientRect().top + window.pageYOffset - paddingTop;

        window.scrollTo({
            top: targetOffsetTop,
            behavior: 'smooth'
        });
        return false;
    } else {
        console.error("Element not found: " + target);
    }
};

window.applySelectLogic = function applySelectLogic() {
    var selectElement = document.getElementById('whereSelectApply');
    var textareaElement = document.getElementById('whereTextareaApply');
    console.log(selectElement)

    selectElement.addEventListener('change', function () {
        console.log(selectElement.value)
        if (selectElement.value === 'Other') {
            selectElement.style.display = 'none';
            textareaElement.style.display = 'block';
        } else {
            selectElement.style.display = 'block';
            textareaElement.style.display = 'none';
        }
    });
};
