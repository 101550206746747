const tabButton = document.querySelectorAll('.industries__tab-head-button');
const mainTabInfo = document.querySelectorAll('.industries__tab-content-wrapper');
const openTitle =  document.querySelector('.industries__tab-head-open');

/*let tabButton = document.querySelectorAll('.cont-tabs-switchers-item');*/
let infoTabsList = document.querySelector('.cont-tab-info-list');
let tmpId = 0;
let currentId = 0;
let tmpTitle = '';
let currentTitle = '';
let infoTabs = document.querySelectorAll('.industries__tab-content');
if (tabButton) {
    tabButton.forEach((button, idx) => {
        button.addEventListener('click', function () {

            tmpTitle = infoTabs[tmpId].querySelector('.industries__tab-head').innerHTML;
            this.innerHTML = tmpTitle;

            tmpId = this.dataset.id;

            let activeTab = document.querySelector('.industries__tab-content.active');
            this.dataset.id = activeTab.dataset.id;


            infoTabs.forEach((tab, idx) => {
                if (idx != tmpId) {
                    tab.classList.remove('active');
                } else {
                    tab.classList.add('active');
                }
            })
/*
            if (window.innerWidth <= 992) {
                let padding = document.querySelector('.header').clientHeight + 10;
                const y = infoTabsList.getBoundingClientRect().top + window.scrollY - padding;
                window.scroll({
                    top: y,
                    behavior: 'smooth'
                });
            }*/

        })
    })


}

