import Aos from 'aos';

window.addEventListener('load', Aos.refresh)
window.addEventListener('DOMContentLoaded', () => {
	Aos.init({
		startEvent: 'load',
		offset: innerWidth > 640 ? 150 : 40,
		delay: 0,
		duration: 500,
		easing: "ease",
		once: true,
	});
})