import Swiper from 'swiper';
import {Autoplay, EffectCreative, EffectFade, Navigation, Controller} from 'swiper/modules';
import { swiperObserver } from '../utils/swiper.utils.js';

let storySwiperWr = document.querySelectorAll('.story-swiper-wr');
storySwiperWr.forEach((el) => {
  if (el) {
    let dateSwiperEl = el.querySelector('.swiper-story-date');
    let textSwiperEl = el.querySelector('.swiper-story-text');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;

    let cardsSwiper = new Swiper(dateSwiperEl, {
      modules: [Autoplay, Navigation, Controller],
      spaceBetween: 20,
      slidesPerView: 1,
      speed: 800,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints:{

      },

      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });

    let textSwiper = new Swiper(textSwiperEl, {
      modules: [Autoplay, Navigation, EffectFade, Controller],
      spaceBetween: 20,
      slidesPerView: 1,
      speed: 800,
      threshold: 10,
      effect:'fade',
      autoHeight: true,
      fadeEffect:{
        crossFade: true,
      },
    });

    swiperObserver(cardsSwiper);

    cardsSwiper.controller.control = textSwiper;
    textSwiper.controller.control = cardsSwiper;
  }
});
