const customAccordion = (elements, trigger, openOne=false) => {
    if (openOne) {
        elements.forEach(accordion => {
            const triggerBtn = accordion.querySelector(trigger);
            triggerBtn.addEventListener('click', () => {
                if(!accordion.classList.contains('is-active')) {
                    elements.forEach(accordion => {
                        accordion.classList.remove('is-active');
                    })
                    accordion.classList.add('is-active');
                } else {
                    accordion.classList.remove('is-active');
                }
            })
        })
    } else {
        elements.forEach(accordion => {
            const triggerBtn = accordion.querySelector(trigger);
            triggerBtn.addEventListener('click', () => {
                accordion.classList.toggle('is-active');
            })
        })
    }
}

if (document.querySelector(".search__accordion-box")) {
    const accordions = document.querySelectorAll('.search__accordion-box');
    customAccordion(accordions, '.search__head')
}
if (document.querySelector(".faq__accordion-box")) {
    const accordions = document.querySelectorAll('.faq__accordion-box');
    customAccordion(accordions, '.faq__head', true)
}
if (document.querySelector(".industries-accordion__accordion-box")) {
    const accordions = document.querySelectorAll('.industries-accordion__accordion-box');
    customAccordion(accordions, '.industries-accordion__head', true)
}