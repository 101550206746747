window.initChoices = function initChoices() {
    const choicesArr = document.querySelectorAll(".js-choices");
    if (choicesArr) {
        choicesArr.forEach(select => {
            new Choices(select, {
                allowHTML: true,
                searchEnabled: select.classList.contains('search-select') ? true : false,
                searchPlaceholderValue: 'Search...',
                shouldSort: false,
            });
        });
    }


    if (document.querySelector(".choices")) {
        const choicesWrappers = document.querySelectorAll(".choices");
        choicesWrappers.forEach(choiceWrap => {
            choiceWrap.addEventListener("click", () => {

                if (!choiceWrap.querySelector(".custom-simplebar-wrapper")) {
                    const selectSimplebar = choiceWrap.querySelector(".choices__list--dropdown .choices__list");
                    const div = document.createElement("div");
                    div.classList.add("custom-simplebar-wrapper");
                    const content = selectSimplebar.innerHTML;
                    selectSimplebar.innerHTML = "";
                    div.setAttribute("data-simplebar", "");
                    div.innerHTML = content;
                    selectSimplebar.appendChild(div);
                }
            });
        });
    }
}

initChoices();