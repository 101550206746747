import Swiper from 'swiper';
import { Autoplay, EffectFade } from 'swiper/modules';


let heroCarouselSwiperWr = document.querySelectorAll('.hero-carousel-swiper-wr');
heroCarouselSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let swiper = new Swiper(swiperEl, {
        modules: [Autoplay, EffectFade],
        allowTouchMove: false,
        speed: 800,
        effect: 'fade',
        fadeEffect:{
            crossFade: true
        },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
        },

    })
  }
});
