import scrollLock from 'scroll-lock';

const burgerMenu = (
    headerSelector,
    burgerSelector,
    layout,
    mediaWidth,
    hideOnScroll = false
) => {
    const menuElem = document.querySelector(headerSelector),
        layoutElem = document.querySelector(layout),
        burgerElem = document.querySelectorAll(burgerSelector);

    const removeClass = () => {
        layoutElem.classList.remove('active');
        menuElem.classList.remove('active');
        scrollLock.enablePageScroll();
    };
    removeClass();

    menuElem.querySelectorAll('a').forEach((link) => {
        link.addEventListener('click', () => {
            menuElem.classList.contains('active') && removeClass();
        });
    });

   if (menuElem.classList.contains('search__filter-box')) {
       const btnSearch = document.querySelector('.search__btn')
       btnSearch.addEventListener('click', removeClass)
   }


    burgerElem.forEach((burgerBtn) => {
        burgerBtn.addEventListener('click', () => {
            if (menuElem.classList.contains('active')) {
                removeClass();
            } else {
                layoutElem.classList.add('active');
                menuElem.classList.add('active');
                const method = 'margin';
                scrollLock.setFillGapMethod(method);
                scrollLock.disablePageScroll();

                if (document.querySelector('.header__simplebar') && innerWidth < (mediaWidth - 1) || document.querySelector('.search__filter-box') && innerWidth < (mediaWidth - 1)) {
                    scrollLock.addScrollableSelector('.simplebar-content-wrapper');
                }

                scrollLock.addScrollableSelector('.choices__list');

                scrollLock.addFillGapSelector('.header__navbar-box');
                scrollLock.addFillGapSelector('.search__filter-box');
            }
        });
    });

    window.addEventListener('resize', () => {
        innerWidth > mediaWidth && removeClass();
    });

    layoutElem.addEventListener('click', () => {
        removeClass();
    });
    let scrollPoint;
    const stickyMenu = () => {
        window.scrollY >= 50
            ? menuElem.classList.add('sticky')
            : menuElem.classList.remove('sticky');

        if (hideOnScroll) {
            if (window.scrollY > scrollPoint && innerWidth > (mediaWidth - 1)) {
                menuElem.style.transform = 'translateY(-100%)';
            } else {
                menuElem.style.transform = 'unset';
            }
            scrollPoint = window.scrollY;
        }
    };

    window.addEventListener('scroll', stickyMenu);
    stickyMenu();
};

if (document.querySelector('.header')) {
    burgerMenu('.header', '.header__burger', '.header__layout', 1025, true);
}

if (document.querySelector('.search__filter-box')) {
    burgerMenu(".search__filter-box", ".search__burger-box", ".search__layout", 1025);
}
