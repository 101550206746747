import marquee from 'vanilla-marquee'

let marqueeGallery = document.querySelectorAll('.marquee-gallery')
if(marqueeGallery){
  marqueeGallery.forEach(gallery =>{
    let marqueeEl = new marquee( gallery, {
      duplicated: true,
      startVisible: true,
      speed: 30,
      gap:16,
    })
  })
}
