import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap  from "gsap/all";

// gsap
gsap.registerPlugin(ScrollTrigger);

function animateElement(selector, startOptions, endOptions, triggerElement, triggerEnd) {
    gsap.fromTo(selector,
        startOptions,
    {
      ...endOptions,
      scrollTrigger: {
        trigger: triggerElement,
        end: triggerEnd,
        scrub: true
      }
    });
}

if (ScrollTrigger.isTouch !== 1) {
  // index
  animateElement(
    '.js-gsap-about',
    { y: -500, x: -500, opacity: 0 },
    { y: 0, x: 0, opacity: 1 },
    '.about', 'top'
  );
  animateElement(
    '.js-gsap-sector',
    { y: -400, opacity: 0 },
    { y: 0, opacity: 1 },
    '.sectors', 'top'
  );
  animateElement(
    '.js-gsap-group',
    { x: -1000, opacity: 0, rotate: 50 },
    { x: 0, opacity: 1, rotate: 0 },
    '.testimonials', 'center'
  );
  animateElement(
    '.js-gsap-footer',
    { y: -500, opacity: 0, rotate: 50 },
    { y: 0, opacity: 1, rotate: 0 },
    '.footer', 'top'
  );

  //what-we-do
  animateElement(
    '.js-gsap-industries',
    { y: 500,  opacity: 0 },
    { y: 0,  opacity: 1 },
    '.industries', 'top'
  );
  animateElement(
    '.js-gsap-industries-tab',
    { y: -500,  opacity: 0, rotate: 50 },
    { y: 0,  opacity: 1, rotate: 0 },
    '.industries__tab-box', 'top'
  );
  animateElement(
    '.js-gsap-values-section',
    { y: -500,  opacity: 0, rotate: 50 },
    { y: 0,  opacity: 1, rotate: 0 },
    '.values-section', 'top'
  );
  animateElement(
    '.js-gsap-mission',
    { x: 500,  opacity: 0, rotate: 0 },
    { x: 0,  opacity: 1, rotate: 0 },
    '.mission-section', 'top'
  );


  //meet-the-team
  animateElement(
    '.js-gsap-team-1',
    { x: 0, y: 0, rotate: 0 },
    { x: 500, y: 200,  rotate: 50 },
    '.team', 'bottom'
  );
  animateElement(
    '.js-gsap-team-2',
    { x: -500, y: -200,  rotate: 50 },
    { x: 0, y: 0, rotate: 0 },
    '.team', 'bottom'
  );

  //join-the-team
  animateElement(
    '.js-gsap-offer',
    { y: 500,  opacity: 0, rotate: 0 },
    { y: 0,  opacity: 1, rotate: 0 },
    '.offer-section', 'top'
  );
  animateElement(
    '.js-gsap-dev',
    { y: -500,  opacity: 0, rotate: 0 },
    { y: 0,  opacity: 1, rotate: 0 },
    '.dev-section', 'top'
  );
  animateElement(
    '.js-gsap-culture',
    { x: 500,  opacity: 0, rotate: 30 },
    { x: 0,  opacity: 1, rotate: 0 },
    '.culture-section', 'top'
  );
  animateElement(
    '.js-gsap-talent',
    { y: -500,  opacity: 0, rotate: 30 },
    { y: 0,  opacity: 1, rotate: 0 },
    '.talent-section-wr', 'center'
  );

  // looking-for-work
  animateElement(
    '.js-gsap-process',
    { x: -500, y: -400,  rotate: 50 , opacity: 0 },
    { x: 0, y: 0, rotate: 0, opacity: 1 },
    '.process', 'top'
  );
  animateElement(
    '.js-gsap-industries-accordion',
    { y: -500,  opacity: 0 },
    { y: 0,  opacity: 1 },
    '.industries-accordion', 'top'
  );
  animateElement(
    '.js-gsap-information-1',
    { y: -500, x: 100, opacity: 0 },
    { y: 0, x: 100, opacity: 1 },
    '.information', 'top'
  );

  // news
  animateElement(
    '.js-gsap-news-list',
    { y: -1000, x:-1000, opacity: 0, rotate: 50 },
    { y: 0, x:0, opacity: 1, rotate: 0 },
    '.news-list', 'bottom'
  );
  // jobs
  animateElement(
    '.js-gsap-search',
    { y: -1000, x:1000, opacity: 0, rotate: 50 },
    { y: -100, x:0, opacity: 1, rotate: 0 },
    '.search', 'bottom'
  );
  // jobs-posting
  animateElement(
    '.js-gsap-details',
    { y: -1000, x:-1000, opacity: 0, rotate: 50 },
    { y: 0, x:0, opacity: 1, rotate: 0 },
    '.details', 'bottom'
  );

  //looking-to-hire
  animateElement(
    '.js-gsap-case',
    { y: -500,  opacity: 0, rotate: 30 },
    { y: 0,  opacity: 1, rotate: 0 },
    '.case-studies-section', 'top'
  );
  animateElement(
    '.js-gsap-testimonials',
    { x: 500,  opacity: 0, rotate: 30 },
    { x: 0,  opacity: 1, rotate: 0 },
    '.testimonials-slider-section', 'top'
  );
  animateElement(
    '.js-gsap-brief',
    { y: -500,  opacity: 0, rotate: 30 },
    { y: 0,  opacity: 1, rotate: 0 },
    '.brief-section', 'top'
  );

  // blog-details
  animateElement(
    '.js-gsap-blog',
    { y: -1000, x:1000, opacity: 0, rotate: 50 },
    { y: 0, x:0, opacity: 1, rotate: 0 },
    '.blog', 'bottom'
  );
}
