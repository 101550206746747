import SimpleBar from 'simplebar';

const classToggler = (selectors) => {
    const containers = document.querySelectorAll(selectors);
    containers.forEach(container  => {
        const scrollableContainer = new SimpleBar(container);
        const scrollBar = container.querySelector('.simplebar-vertical .simplebar-scrollbar');
        
        if (scrollBar.style.display === 'none') {
            container.classList.add('is-bottom');
        }

        scrollableContainer.getScrollElement().addEventListener('scroll', function () {
            let isAtBottom = (this.scrollTop + this.clientHeight + 10 >= this.scrollHeight);
        
            if (isAtBottom) {
                container.classList.add('is-bottom');
            } else {
                container.classList.remove('is-bottom');
            }
        });
    })
}

classToggler('.js-scroll-gradient')