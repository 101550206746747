import noUiSlider from 'nouislider';

window.doubleRangeSliderTooltips = function doubleRangeSliderTooltips(rangeSelector){
    const doubleRange = document.querySelector(rangeSelector);
    if (doubleRange) {
        let slider = doubleRange.querySelector("#double-range-tooltips");
        let max = +slider.dataset.max;
        let min = +slider.dataset.min;
        let unit = slider.dataset?.unit || "£";
        let step = +slider.dataset.step;
        let inputsHidden = doubleRange.querySelectorAll(".search__range-input");
        let startValueMin = +inputsHidden[0].value;
        let startValueMax = +inputsHidden[1].value;

        // how many percentages
        const borderLimit = 8;
        const borderDiff = 40 / borderLimit;

        noUiSlider.create(slider, {
            start: [startValueMin, startValueMax],
            connect: true,
            tooltips: true,
            margin: 10,
            step: step,
            range: {
                min: min,
                max: max,
            },
        });

        const tooltipsArr = slider.querySelectorAll(".noUi-tooltip");
        const circlesArr = slider.querySelectorAll(".noUi-origin");

        function returnTransform(element) {
            return element
                .replace(/[^0-9][^\d.]/g, "")
                .replace(")", "")
                .split(" ")
                .map((str) => {
                    return Number(str);
                });
        }

        function needToMerge() {
            let tooltipOnePosition = tooltipsArr[0].getBoundingClientRect();
            let tooltipTwoPosition = tooltipsArr[1].getBoundingClientRect();

            if (
                tooltipsArr[0].classList.contains("hidden") ||
                tooltipsArr[1].classList.contains("hidden")
            ) {
                return true;
            }

            return (
                tooltipOnePosition.left +
                tooltipOnePosition.width -
                tooltipTwoPosition.left >
                0
            );
        }

        function resetTooltips(values) {
            mergeDiff = null;
            tooltipsArr.forEach((elem, index) => {
                elem.textContent =
                    Math.round(values[index]).toLocaleString("en-GB") + ' ' + unit;
                elem.classList.remove("hidden");
            });
        }

        let trueTooltip = false;
        let mergeDiff = null;

        slider.noUiSlider.on("update", function (values, handle) {
            let translate = returnTransform(circlesArr[handle].style.transform)[0];
            let valueMin = returnTransform(circlesArr[0].style.transform)[0];
            let valueMax = returnTransform(circlesArr[1].style.transform)[0];

            let difference = valueMax - valueMin;

            inputsHidden[handle].value = Math.round(values[handle]);

            if (translate <= -100 + borderLimit) {
                tooltipsArr[0].style.transform = `translate(${
                    -60 + Math.abs(translate + 100 - borderLimit) * borderDiff
                }%,100%)`;
            } else if (translate >= -borderLimit) {
                tooltipsArr[1].style.transform = `translate(${
                    -50 - Math.abs(translate + borderLimit) * borderDiff
                }%,100%)`;
            } else {
                tooltipsArr[handle].style.transform = "translate(-50%,100%)";
            }

            if (needToMerge()) {
                if (
                    !tooltipsArr[+!handle].classList.contains("hidden") &&
                    !tooltipsArr[handle].classList.contains("hidden")
                ) {
                    trueTooltip = handle;
                    mergeDiff = difference;
                    tooltipsArr[+!handle].classList.add("hidden");
                }

                if (trueTooltip) {
                    tooltipsArr[trueTooltip].style.transform = `translate(${
                        -10 - difference
                    }%,100%)`;
                } else {
                    // if grabbed right dot
                    tooltipsArr[trueTooltip].style.transform = `translate(${
                        -95 + difference
                    }%,100%)`;
                }

                tooltipsArr[trueTooltip].textContent = `${
                    Math.round(values[0]).toLocaleString("en-GB") + ' ' + unit
                } - ${Math.round(values[1]).toLocaleString("en-GB") + ' ' + unit}`;

                if (mergeDiff && difference > mergeDiff) {
                    mergeDiff = null;
                    resetTooltips(values);

                    if (trueTooltip) {
                        tooltipsArr[0].style.transform = `translate(-20%, 100%)`;
                    } else {
                        tooltipsArr[1].style.transform = `translate(-90%, 100%)`;
                    }
                }
            } else {
                resetTooltips(values);
            }
        });

        return {slider, noUiSlider};
    }

};

let searchRangeWrappers = $('.search-range-wrapper')
if(searchRangeWrappers.length){
    searchRangeWrappers.each((idx,wrapper)=>{
        let checkbox = $(wrapper).find('.search__checkbox input[type=checkbox]');
        checkbox.on('change', function(){
            if(this.checked){
                $(wrapper).find('.search__range-box').slideDown();
            }
           else{
                $(wrapper).find('.search__range-box').slideUp();
            }
        })
        setTimeout(function(){
            if(checkbox.is(':checked')){
                $(wrapper).find('.search__range-box').slideDown();

            }
        },200)

    })
}

