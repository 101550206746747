import Parallax from 'parallax-js';
const heroSection = document.querySelector(".hero");
const paralaxContainer = document.querySelectorAll(".parallax");

if (heroSection && paralaxContainer.length && innerWidth > 1024) {

    paralaxContainer.forEach((elem) => {
        let parallaxInstance = new Parallax(elem);
        parallaxInstance.enable();
    });

    const heroDecorWrapper = document.querySelector('.hero__title-box');
    const heroDecor = document.querySelector('.hero__decor div');

    function updateCoordinates() {
        let rect = heroDecor.getBoundingClientRect();
        let containerRect = heroDecorWrapper.getBoundingClientRect();
    
        let topPos = `${Math.round(rect.top - containerRect.top)}px`;
        let leftPos = `${Math.round(rect.left - containerRect.left)}px`;
    
        document.documentElement.style.setProperty('--topPos', topPos);
        document.documentElement.style.setProperty('--leftPos', leftPos);
    
        requestAnimationFrame(updateCoordinates);
    }
    
   
    requestAnimationFrame(updateCoordinates);
}
