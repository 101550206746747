import Swiper from 'swiper';
import { Autoplay, EffectFade, EffectCreative, Controller,  Navigation, Grid } from 'swiper/modules';

if (document.querySelector('.jobs--home')) {
    const slider = document.querySelector('.jobs__slider');
    const wrapper = slider.querySelector('.swiper-wrapper');
    const slides = slider.querySelectorAll('.swiper-slide');

	const swiper = new Swiper(slider, {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
        spaceBetween: 30,
		speed: 800,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".jobs .slider-btn.prev",
            nextEl: ".jobs .slider-btn.next",
		},
        breakpoints: {
            650: {
                spaceBetween: 20,
                slidesPerView: 2,
            },

            1024: {
                spaceBetween: 13,
                slidesPerView: 3,
            },
        },
	});
    if (slides.length < swiper.params.slidesPerView) {
       /* wrapper.classList.add('center-slides')*/
    }
}
if (document.querySelector('.jobs--looking')) {
    const slider = document.querySelector('.jobs__slider');
    const wrapper = slider.querySelector('.swiper-wrapper');
    const slides = slider.querySelectorAll('.swiper-slide');

	const swiper = new Swiper(slider, {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
        spaceBetween: 30,
		speed: 800,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".jobs .slider-btn.prev",
            nextEl: ".jobs .slider-btn.next",
		},
        breakpoints: {
            650: {
                spaceBetween: 20,
                slidesPerView: 2,
            },

            1024: {
                spaceBetween: 13,
                slidesPerView: 3,
            },
        },
	});
    if (slides.length < swiper.params.slidesPerView) {
        /*wrapper.classList.add('center-slides')*/
    }
}



let jobsWeDoSwiperWr = document.querySelectorAll('.jobs--wedo');
if (jobsWeDoSwiperWr) {

    jobsWeDoSwiperWr.forEach((el) => {
        if (el) {

            let swiperEl = el.querySelector('.jobs__slider');
            let nextEl = el.querySelector('.slider-btn.next');
            let prevEl = el.querySelector('.slider-btn.prev');
            let slidesCount = el.querySelectorAll('.swiper-slide').length;
            let wrapper = swiperEl.querySelector('.swiper-wrapper');
            const swiper = new Swiper(swiperEl, {
                modules: [Navigation, Autoplay],
                slidesPerView: 1,
                spaceBetween: 30,
                speed: 800,
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                },
                navigation: {
                    prevEl: prevEl,
                    nextEl: nextEl,
                },
                breakpoints: {
                    650: {
                        spaceBetween: 20,
                        slidesPerView: 2,
                    },

                    1024: {
                        spaceBetween: 30,
                        slidesPerView: 3,
                    },
                },
            });
            if (slidesCount < swiper.params.slidesPerView) {
                /*wrapper.classList.add('center-slides')*/
            }
        }
    });

}

if (document.querySelector('.jobs--details')) {
    const slider = document.querySelector('.jobs__slider');
    const wrapper = slider.querySelector('.swiper-wrapper');
    const slides = slider.querySelectorAll('.swiper-slide');

	const swiper = new Swiper(slider, {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
        spaceBetween: 30,
		speed: 800,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".jobs .slider-btn.prev",
            nextEl: ".jobs .slider-btn.next",
		},
        breakpoints: {
            650: {
                spaceBetween: 20,
                slidesPerView: 2,
            },

            1024: {
                spaceBetween: 30,
                slidesPerView: 3,
            },
        },
	});
    if (slides.length < swiper.params.slidesPerView) {
       /* wrapper.classList.add('center-slides')*/
    }
}
if (document.querySelector('.jobs--member')) {
    const slider = document.querySelector('.jobs__slider');
    const wrapper = slider.querySelector('.swiper-wrapper');
    const slides = slider.querySelectorAll('.swiper-slide');

	const swiper = new Swiper(slider, {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
        spaceBetween: 30,
		speed: 800,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".jobs .slider-btn.prev",
            nextEl: ".jobs .slider-btn.next",
		},
        breakpoints: {
            650: {
                spaceBetween: 20,
                slidesPerView: 2,
            },

            1024: {
                spaceBetween: 30,
                slidesPerView: 3,
            },
        },
	});
    if (slides.length < swiper.params.slidesPerView) {
        /*wrapper.classList.add('center-slides')*/
    }
}
if (document.querySelector('.blog')) {
    const slider = document.querySelector('.blog__slider');
    const wrapper = slider.querySelector('.swiper-wrapper');
    const slides = slider.querySelectorAll('.swiper-slide');

	const swiper = new Swiper(slider, {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
        spaceBetween: 10,
		speed: 800,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".blog .slider-btn.prev",
            nextEl: ".blog .slider-btn.next",
		},
        breakpoints: {
            650: {
                slidesPerView: 2,
            },
            769: {
                slidesPerView: 1,
            },
        },
	});
    if (slides.length < swiper.params.slidesPerView) {
        wrapper.classList.add('center-slides')
    }
}
if (document.querySelector('.information')) {
    const slider = document.querySelector('.information__slider');
    const wrapper = slider.querySelector('.swiper-wrapper');
    const slides = slider.querySelectorAll('.swiper-slide');
	const swiper = new Swiper(slider, {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
        spaceBetween: 30,
		speed: 800,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".information .slider-btn.prev",
            nextEl: ".information .slider-btn.next",
		},
        breakpoints: {
            568: {
                spaceBetween: 30,
                slidesPerView: 2,
            },
            769: {
                spaceBetween: 30,
                slidesPerView: 3,
            },
            1024: {
                spaceBetween: 30,
                slidesPerView: 4,
            },
        },

	});

    if (slides.length < swiper.params.slidesPerView) {
        wrapper.classList.add('center-slides')
    }

}
if (document.querySelector('.testimonials-slider')) {
	const swiper = new Swiper('.testimonials-slider__slider', {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
        spaceBetween: 30,
		speed: 800,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".testimonials-slider .slider-btn.prev",
            nextEl: ".testimonials-slider .slider-btn.next",
		},
        breakpoints: {
            650: {
                spaceBetween: 30,
                slidesPerView: 2,
            },
        },
	});

}
if (document.querySelector('.gallery__slider')) {
	const swiper = new Swiper('.gallery__slider', {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
        spaceBetween: 30,
		speed: 800,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".gallery .slider-btn.prev",
            nextEl: ".gallery .slider-btn.next",
		},
        breakpoints: {
            500: {
                slidesPerView: 2,
            },

            1024: {
                slidesPerView: 3,
            },
        },
	});
}

// Instagram swiper
if (document.querySelector(".gallery__insta-slider")) {
    const instaSwiper = new Swiper(".gallery__insta-slider", {
        slidesPerView: "auto",
        modules: [Navigation, Grid],

        grid: {
            rows: 2,
        },

        slidesPerColumn: 3,

        spaceBetween: 16,

        navigation: {
            prevEl: ".gallery__insta-nav .slider-btn.prev",
            nextEl: ".gallery__insta-nav .slider-btn.next",
        },
    });
}

if (document.querySelector('.industries-accordion__slider')) {
    const mainSlider = document.querySelector('.industries-accordion__slider');
    const accordions = document.querySelectorAll('.industries-accordion__accordion-box');



	const swiperMain = new Swiper(mainSlider, {
		modules: [Navigation, Autoplay, EffectFade],
		slidesPerView: 1,
        spaceBetween: 30,
        effect: 'fade',
        allowTouchMove: false,
        noSwiping: true
	});

    accordions.forEach((elem, i) => {
        elem.addEventListener('click', () => {
            console.log(i);
            swiperMain.slideTo(i)
        })
    })


    const innerSliders = mainSlider.querySelectorAll('.industries-accordion__slider-gallery');

    innerSliders.forEach(slider => {
        const prevBtn = slider.querySelector('.slider-btn.prev');
        const nextBtn = slider.querySelector('.slider-btn.next');

        new Swiper(slider, {
            modules: [Navigation, Autoplay, EffectFade],
            slidesPerView: 1,
            spaceBetween: 0,
            navigation: {
                prevEl: prevBtn,
                nextEl: nextBtn,
            },
            speed:800,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
        });
    })
}

// let propertySwiper = document.querySelectorAll('.property-swiper-wr');

// if (propertySwiper) {
//     propertySwiper.forEach(el=>{
//         if(el) {
//             let swiperEl = el.querySelector('.swiper');
//             let nextEl = el.querySelector('.swiper-button-next')
//             let prevEl = el.querySelector('.swiper-button-prev')
//             let slidesCount = el.querySelectorAll('.swiper-slide').length;
//             const swiper = new Swiper(swiperEl, {
//                 modules: [Navigation, Autoplay, EffectCreative],
//                 observer: true,
//                 observeParents: true,

//                 autoplay: {
//                     delay: 4000,
//                     disableOnInteraction: false,
//                     pauseOnMouseEnter: true,
//                 },

//                 navigation: {
//                     prevEl: prevEl,
//                     nextEl: nextEl,
//                 },
//                 slidesPerView: 1,
//                 effect: "creative",
//                 creativeEffect: {
//                     limitProgress: 5,
//                     prev: {
//                         translate: ['calc(-100% - 30px)', 0, 0],
//                     },
//                     next: {
//                         translate: ['calc(50% + 30px)', 0, 0],
//                     },
//                 },
//             });

//         }

//     })
// }

if (document.querySelector('.property')) {
    const propertySections = document.querySelectorAll('.property');

    propertySections.forEach(section => {
        const slider = section.querySelector('.property__slider');
        const btnPrev = section.querySelector('.swiper-button-prev');
        const btnNext = section.querySelector('.swiper-button-next');
        const swiper = new Swiper(slider, {
            modules: [Navigation, Autoplay],
            // slidesPerView: 'auto',
            // spaceBetween: 30,
            // // speed: 500,
            // // autoplay: {
            // //     delay: 4000,
            // //     disableOnInteraction: false,
            // //     pauseOnMouseEnter: true,
            // // },
            navigation: {
                prevEl: btnPrev,
                nextEl: btnNext,
            },
            // onSlideChangeEnd:function(e){
            //     swiper.update(true);
            //   }
                slidesPerView: 1,
                loop: true,
                spaceBetween: 30,
                speed: 500,
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                },
                breakpoints: {
                    568: {
                        slidesPerView: 2,
                    },
                    769: {
                        slidesPerView: 3,
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                },
        });

    })


}

if (document.querySelector('.clients__slider ')) {
	const swiper = new Swiper('.clients__slider ', {
		modules: [Navigation, Autoplay],
		slidesPerView: 2,
        spaceBetween: 20,
		speed: 800,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
        breakpoints: {
            568: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            769: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
            1024: {
                slidesPerView: 6,
                spaceBetween: 30,
            },
        },
	});
}
if (document.querySelector('.counter__slider')) {
	const swiper = new Swiper('.counter__slider', {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
        spaceBetween: 30,
		speed: 800,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".counter .slider-btn.prev",
            nextEl: ".counter .slider-btn.next",
		},
        breakpoints: {
            568: {
                slidesPerView: 2,
            },
            769: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            },
        },
	});
}
if (document.querySelector('.testimonials__slider')) {
    const swiper = new Swiper('.testimonials__slider', {
        modules: [Navigation, Autoplay, EffectFade],
        slidesPerView: 1,
        spaceBetween: 30,
        effect: 'fade',
        speed: 500,
        autoplay: {
            delay: 6000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        navigation: {
            prevEl: ".testimonials .slider-btn.prev",
            nextEl: ".testimonials .slider-btn.next",
        },
    });
}
if (document.querySelector('.testimonials__slider')) {
	const swiper = new Swiper('.testimonials__slider', {
		modules: [Navigation, Autoplay, EffectFade],
		slidesPerView: 1,
        spaceBetween: 30,
        effect: 'fade',
		speed: 800,
		autoplay: {
            delay: 6000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".industries .slider-btn.prev",
            nextEl: ".industries .slider-btn.next",
		},
	});
}

if (document.querySelector('.sectors')) {
	const swiperCards = new Swiper('.sectors__slider-cards', {
		modules: [Navigation, Autoplay, Controller, EffectCreative],
		slidesPerView: 1,
        spaceBetween: 30,

        effect: "creative",
        creativeEffect: {
          limitProgress: 1,
          prev: {
            translate: [0, 0, -320],
            opacity: 0,
          },
          next: {
            translate: ["34px", "33px", 0],
            opacity: 1,
          },
        },

        breakpoints: {
            1024: {
                creativeEffect: {
                    limitProgress: 1,
                    prev: {
                      translate: [0, 0, -320],
                      opacity: 0,
                    },
                    next: {
                      translate: ["60px", "58px", 0],
                      opacity: 1,
                    },
                  },
            },
        },
	});
	const swiperText = new Swiper('.sectors__slider-text', {
		modules: [Navigation, Autoplay,  Controller, EffectFade],
		slidesPerView: 1,
        spaceBetween: 30,
        effect: 'fade',
		speed: 800,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
        navigation: {
            prevEl: ".sectors .slider-btn.prev",
            nextEl: ".sectors .slider-btn.next",
		},
	});

    swiperText.controller.control = swiperCards;
    swiperCards.controller.control = swiperText;
}
