import Swiper from 'swiper';
import { Autoplay, EffectCreative, EffectFade, Navigation, Controller } from 'swiper/modules';
import { swiperObserver } from '../utils/swiper.utils.js';

let devSwiperWr = document.querySelectorAll('.dev-swiper-wr');
devSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, EffectCreative],
      slidesPerView: 1,
      speed: 800,
      threshold: 10,
      effect: 'creative',
      rtl: true,
      loop:true,
      creativeEffect:{
        next:{
          scale: 0.62,
          translate: [-290, 0, 0],
        },
        prev:{
          scale: 0.62,
          translate: [-290, 0, 0],
        }
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },

      breakpoints:{
        993:{
          creativeEffect:{
            next:{
              scale: 0.72,
              translate: [-361, 0, 0],
            },
            prev:{
              scale: 0.72,
              translate: [-361, 0, 0],
            }
          },
        }
      },

     autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });
   /* swiperObserver(swiper);*/
  }
});


 function fixTopLine(){
   let optionCardTitle = document.querySelector('.option-card h3');
   let optionList = document.querySelector('.dev-section-options');

   if(optionCardTitle && optionList){
     console.log(optionCardTitle.clientHeight)
     optionList.style.setProperty('--line-offset-top', optionCardTitle.clientHeight / 2 + 'px')
   }
 }

fixTopLine();

 window.addEventListener('resize', fixTopLine);

