window.setSameHeight = function setSameHeight(elArr) {
    let minHeight = 0;
    let arr = document.querySelectorAll(elArr);
    if (arr) {
        arr.forEach(el => {
               el.style.minHeight = 0;
        })
        arr.forEach(el => {
            if (minHeight < el.clientHeight) {
                minHeight = el.clientHeight;
            }
        })
        arr.forEach(el => el.style.minHeight = minHeight + 'px')
    }
}

setSameHeight('.team__wrapper .team__name');
/*setSameHeight('.team__wrapper .team__position');*/
setSameHeight('.download-section');
window.addEventListener('resize', function(){
    setSameHeight('.team__wrapper .team__name');
  /*  setSameHeight('.team__wrapper .team__position');*/
    setSameHeight('.download-section');
})

